import toast, { Toaster } from 'react-hot-toast';

export const ErrorNotification=(message)=>{
    toast.error(message, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: true,
        style: {
          background: 'red',
          color:"white"

        },
      
        });

}


export const SuccessNotification=(message)=>{
    toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        style: {
          background: '#228b22',
          color:"white"
        },
        });

}

