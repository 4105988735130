import React, { useEffect, useState } from "react";
import "./auth.css";
import { useLocation, useHistory, NavLink } from "react-router-dom";
import { LOGINADMIN } from '../Graphql/Queries'
import {
  SuccessNotification,
  ErrorNotification,
} from "../components/Notification";
import { Spinner } from "react-bootstrap";
import bcrypt from "bcryptjs";
import { useLazyQuery,useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "../Graphql/Mutations";

function Login(props) {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setpassword] = useState('')
  const [newpassword, setNewpassword] = useState('')
  const [resetpasswordrequest, setresetpasswordrequest] = useState(false)
  const comparepassword = (userpassword, newpassword) => {
    var ispassed = bcrypt.compareSync(userpassword, newpassword);
    return ispassed;
  }


  const [LoginUser, { error, loading: RequestLoading }] = useLazyQuery(
    LOGINADMIN,
    {
      onCompleted: (data) => login(data),
      onError: (error) => ErrorNotification(error),
    }
  );

  const hashpassword = (password) => {
    var salt = bcrypt.genSaltSync(10);
    var hash = bcrypt.hashSync(password, salt);

    return hash

  }


  const [changepassword, { resetpasswordError, resetpasswordLoadingError }] = useMutation(CHANGE_PASSWORD, {
    onCompleted: (data) => {

        SuccessNotification("You have successfully resetted user password");
        localStorage.setItem("token", data.update_admins_by_pk.id)
        history.push("/admin/dashboard")

    },
    onError: (error) => ErrorNotification("An error occurred while updating password"),
})



  const resetpassword = (e) => {
    e.preventDefault();
    let userdata=JSON.parse(localStorage.getItem("auth"))
    const user_id=userdata.id

    const password=hashpassword(newpassword)
    console.log(newpassword)
    console.log(password)

    changepassword({
      variables: {

          "admin_id": user_id,
          "password": password

      }
  });




  }

  useEffect(() => {
    const ispassword = localStorage.getItem("reset-password")
    if (ispassword) {
      setresetpasswordrequest(true)
    }
    else {

      setresetpasswordrequest(false)

    }

  }, [])



  const initiatelogin = (e) => {
    e.preventDefault();
    try {
      LoginUser({
        variables: {
          email: email,
        },
      });
    } catch (error) { console.log(error) }

  }

  const login = (data) => {

    if (data.admins.length > 0) {
      const userdata = data.admins[0]

      if (comparepassword(password, userdata.password)) {
        SuccessNotification("Successfully login")

       

        if (userdata.reset_password_request) {
          localStorage.setItem("reset-password", true)
          localStorage.setItem("auth", JSON.stringify(userdata))
          setresetpasswordrequest(true)
      

        }
        else {
          localStorage.setItem("reset-password", false)
          localStorage.setItem("auth", JSON.stringify(userdata))
          localStorage.setItem("token", userdata.id)
          history.push("/admin/dashboard")

        }

      }
      else {
        ErrorNotification("Wrong credentials provided")

      }

    }
    else {
      ErrorNotification("No admin found for this credentials")
    }


  }

  return (
    <div>
      <main className="d-flex w-100">
        <div className="container d-flex flex-column">
          <div className="row vh-100">
            <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center mt-4">
                  {resetpasswordrequest ? <p>Reset your password</p> : <h1 className="h2">Welcome back, admin</h1>}
                </div>

                <div className="card">
                  <div className="card-body">
                    <div className="m-sm-4">
                      {/* <div className="text-center">
										<img src="img/avatars/avatar.jpg" alt="Charles Hall" className="img-fluid rounded-circle" width="132" height="132" />
									</div> */}
                      <form>
                        {resetpasswordrequest ? <div>
                          <label className="form-label">Password</label>
                          <input
                            onChange={(e) => setNewpassword(e.target.value)}
                            className="form-control form-control-lg"
                            type="text"
                            name="newpassword"
                          />



                        </div> : <div style={{ display: "flex", flexDirection: "column" }}>
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input
                              onChange={(e) => setEmail(e.target.value)}
                              className="form-control form-control-lg"
                              type="email"
                              name="email"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Password</label>
                            <input
                              onChange={(e) => setpassword(e.target.value)}
                              className="form-control form-control-lg"
                              type="password"
                              name="password"
                            />

                          </div>
                          <div>
                            <label className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="remember-me"
                                name="remember-me"
                                checked
                              />
                              <span className="form-check-label">
                                Keep me logged in
                              </span>
                            </label>
                          </div>
                        </div>}

                        {resetpasswordrequest ? <div className="text-center mt-3" >
                          <button
                            onClick={(e) => resetpassword(e)}
                            className="btn btn-lg btn-primary"

                            style={{ width: "9rem" }}
                          >
                            {resetpasswordLoadingError ? <Spinner animation="grow" /> : "Reset password"}

                          </button>
                        </div> : <div className="text-center mt-3" >
                          <button
                            onClick={(e) => initiatelogin(e)}
                            className="btn btn-lg btn-primary"

                            style={{ width: "9rem" }}
                          >
                            {RequestLoading ? <Spinner animation="grow" /> : "Login"}

                          </button>
                        </div>}


                      </form>
                    </div>
                    {/* <div style={{ textAlign: "center", marginTop: 20 }}>
                      NB: Default password is: !new_Admin (Remove this for production)
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Login;
