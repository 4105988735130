import React from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import SomethingWentWrong from "../auth/404";
import Login from "../auth/login";
import Register from "../auth/Register";
const DashboardLayout = React.lazy(() => import("../layouts/DashboardLayout"));

function ProtectedRoutes({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (localStorage.token) {
          return <Component />;
        } else {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }
      }}
    />
  );
}

export default ProtectedRoutes;

export const PublicRoute = ({ component: Component, ...rest }) => {
  let isLoggedIn = localStorage.token ? true : false;
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Redirect to="admin/dashboard" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export const Content = () => {
  return (
    <Router>
      <Switch>
        <ProtectedRoutes path="/admin" component={DashboardLayout} />
        <Route path={"/404"} component={SomethingWentWrong} />
        <PublicRoute path="/register" component={Register} />
        <PublicRoute path="/"  component={Login} />
       
        <Redirect to="/404" />
      </Switch>
    </Router>
  );
};
