import { useQuery, gql } from '@apollo/client'

export const LOGINADMIN = gql`
  query getAdminUserByEmail($email: String) {
    admins(where: { email: { _eq: $email } }) {
      id
      name
      email
      phone
      password
      role
      reset_password_request
    }
  }
`

export const GET_VEHICLE_MODEL_BY_PARENT_ID = gql`
  query getVehicleModelByParentID($parent_ID: Int) {
    references(where: { parent_id: { _eq: $parent_ID } }) {
      name
    }
  }
`

export const GET_VEHICLE_MAKES = gql`
  query getVehicleMakes {
    references(where: { is_parent: { _eq: true } }) {
      id
      name
    }
  }
`

export const GET_MOTOR_INSURANCE_OPTIONS = gql`
  query getMotorOptions {
    request_types(where: { id: { _eq: 1 } }) {
      properties
    }
  }
`

export const GET_CUSTOMER_MOTOR_REQUEST_DETAILS = gql`
  query GetMotorRequestByRequestID($requestID: uuid) {
    requests(where: { id: { _eq: $requestID } }) {
      motor_details
    }
  }
`

export const GET_CUSTOMER_HOME_OWNER_REQUEST_DETAILS = gql`
  query GetHomeOwnerRequestByRequestID($requestID: uuid) {
    requests(where: { id: { _eq: $requestID } }) {
      home_details
      quotes {
        amount
        created_at
        id
        proxy {
          name
          id
        }
        insurance_company {
          id
          name
        }
      }
    }
  }
`

// export const LOGINADMIN = gql`
//   query getAdminUserByEmail($email: String) {
//     admin_user(where: { email: { _eq: $email } }) {
//       id

//       name

//       email

//       phone_number

//       password
//     }
//   }
// `;

export const GET_ALL_INTERMEDIATES = gql`
  query getProxies {
    proxies(order_by: { name: asc }) {
      id
      name
      phone_numbers
      type
      emails
      active
      address_line_1
      address_line_2
      digital_address
      created_at
    }
  }
`

export const GET_INTERMEDIARY_ID = gql`
  query getIntermediaryById($intermediary_id: uuid!) {
    intermediary: intermediary_by_pk(id: $intermediary_id) {
      id
      name
      email
      phone_number
      physical_address
      digital_address
      license_number
      license_document
      logo
      intermediary_type
      bank {
        id
        name
      }
      bank_branch
      system_commission_rate
      approved_at
      approver {
        id
        name
      }
      approved_at
      approval_remarks
      approval_status
      created_at
      account_no
    }
  }
`

export const GET_PRODUCT_BY_NAME = gql`
  query getInsuranceProductByName($insurance_type_name: String) {
    insurance_product(
      where: { product_type: { name: { _eq: $insurance_type_name } } }
    ) {
      id

      name

      description

      created_at

      is_active
    }
  }
`

export const GET_ALL_PRODUCTS = gql`
  query getAllRequestTypes @cached {
    request_types(order_by: { code: asc }) {
      id
      name
      code
      active
      properties
      description
      image
      logs
    }
  }
`

export const GET_ALL_DEPARTMENTS = gql`
  query getAllActiveDepartments {
    departments: department {
      id

      name

      is_active
    }
  }
`

// export const GET_ALL_ADMINS = gql`
//   query getAllAdminUsers {
//     admin_user {
//       id

//       name

//       email

//       phone_number

//       is_active

//       role

//       department {
//         id

//         name
//       }
//     }
//   }
// `

export const GET_SMS_LOG = gql`
  query getSMSLog {
    sms_log {
      id

      title

      status

      sent_date

      schedule_date

      message

      sender {
        id

        name
      }

      recipient {
        id

        customers {
          full_name
        }

        intermediary_user {
          name
        }
      }
    }
  }
`

export const GET_INTERMEDIARY_DASHBOARD_POLICY_INFO = gql`
  query getIntermediaryAnalytics($proxyID: uuid) {
    numQuotes: quotes_aggregate(where: { proxy_id: { _eq: $proxyID } }) {
      aggregate {
        count
      }
    }
    numPolicies: policies_aggregate(
      where: { quote: { proxy_id: { _eq: $proxyID } } }
    ) {
      aggregate {
        count
      }
    }
    allQuotes: quotes(where: { proxy_id: { _eq: $proxyID } }) {
      amount
      customer {
        first_name
        last_name
        other_names
      }
      status
      insurance_company {
        name
      }
      request {
        request_type {
          name
        }
      }
    }
    policies(where: { quote: { proxy_id: { _eq: $proxyID } } }) {
      active
      amount
      customer {
        first_name
        last_name
        other_names
      }
      validity_end
      validity_start
      quote {
        request {
          request_type {
            name
          }
        }
        insurance_company {
          name
        }
      }
    }
  }
`

export const GET_ALL_USERS = gql`
  query getUsers {
    customers(order_by: { last_name: asc }) {
      emails
      first_name
      last_name
      other_names
      phone_numbers
    }
    proxies(order_by: { name: asc }) {
      emails
      phone_numbers
      name
    }
  }
`

export const GET_SYSTEM_STATS = gql`
  query getAdminDashBoardNums {
    customers_aggregate {
      aggregate {
        count(distinct: true)
      }
    }
    pendingProxies: proxies_aggregate(where: { active: { _eq: false } }) {
      aggregate {
        count
      }
    }
    activeProxies: proxies_aggregate(where: { active: { _eq: true } }) {
      aggregate {
        count
      }
    }
    policies_aggregate {
      aggregate {
        count
        sum {
          amount
        }
      }
    }
    pendingRequests: requests_aggregate(where: { status: { _eq: "pending" } }) {
      aggregate {
        count
      }
    }
    activeRequests: requests_aggregate(where: { status: { _eq: "active" } }) {
      aggregate {
        count
      }
    }
  }
`

export const GET_ALL_UPLOADED_REQUEST = gql`
  query getUploadedRequests {
    view_requests_vs_uploads(
      where: {
        reference_id: { _neq: "NULL" }
        status: { _eq: "pending" }
        created_by: { _eq: "self_upload" }
      }
      order_by: { created_at: desc }
    ) {
      id
      created_at
      status
    }
  }
`

export const GET_UPLOADED_FILES_OF_REQUEST = gql`
  query getFileByReference($reference: String) {
    files(where: { reference_id: { _eq: $reference } }) {
      id
      key
      type
    }
  }
`

export const LOGIN_ADMIN = gql`
  query getAdmin($email: String) {
    admins(where: { email: { _eq: $email } }) {
      email
      name
      id
      role
    }
  }
`

export const GET_ALL_VEHICLE_INFO = gql`
  query BodyTypesMakeModelCurrencies {
    body_types: body_type(
      order_by: { name: asc }

      where: { is_active: { _eq: true } }
    ) {
      id

      name
    }

    vehicle_makes: vehicle_make(
      order_by: { name: asc }

      where: { is_active: { _eq: true } }
    ) {
      id

      name

      vehicle_models(
        order_by: { name: asc }

        where: { is_active: { _eq: true } }
      ) {
        id

        name
      }
    }

    usage_types(
      order_by: { name: asc }

      where: { is_active: { _eq: true } }
    ) {
      id

      name
    }

    currencies: currency(
      order_by: { name: asc }

      where: { is_active: { _eq: true } }
    ) {
      id

      name

      symbol
    }
  }
`

export const REQUEST_NOT_QUOTED_BY_INTERMEDIARY = gql`
  query RequestsNotQuotedByIntermediary($intermediary_id: uuid) {
    quoted_requests: policy_request(
      where: {
        quotes: {
          intermediary_user: { intermediary_id: { _neq: $intermediary_id } }
        }
      }
    ) {
      id

      request_code

      created_at

      insurance_product {
        id

        name

        product_type {
          id

          name
        }
      }

      motor_request_detail {
        mileage

        color

        usage_type

        cost_price

        increase_thirparty_damage_limit

        registration_number

        chassis_number

        vehicle_model {
          id

          name

          vehicle_make {
            id

            name
          }
        }

        year_of_manufacturing

        body_type {
          id

          name
        }

        seats

        engine_capacity

        year_of_registration

        purchage_date

        altered_vehicle_design

        other_usage

        repair_state
      }

      quotes {
        id

        premium

        proposed_inception_date

        proposed_expiry_date

        state

        created_at

        updated_at

        insurance_company {
          id

          name
        }

        quotation_date

        policy_purchase_date

        customer_review_remarks

        intermediary_review_date

        customer_acknowledgement_date

        broker_review_remarks

        details

        currency {
          id

          name

          symbol
        }

        review_intermediary_user {
          id

          name
        }

        intermediary_user {
          id

          name

          intermediary {
            id

            name

            logo
          }
        }
      }

      insurance_product {
        id

        name
      }

      policy_number

      state

      proposed_inception_date

      proposed_expiry_date

      accepted_quote_id

      payment_status

      payment_channel

      payment_ref

      premium

      payment_date

      customer {
        id

        full_name
      }

      currency {
        id

        name

        symbol
      }

      customer_acceptance_date

      no_claim_years

      buy_back_excess
    }

    unquoted_policy_requests: view_unquoted_policy_requests(
      where: { state: { _neq: "PAID" }, _or: { state: { _neq: "POLICY" } } }
    ) {
      id

      request_code

      created_at

      insurance_product {
        id

        name

        product_type {
          id

          name
        }
      }

      motor_request_detail {
        mileage

        color

        usage_type

        cost_price

        increase_thirparty_damage_limit

        registration_number

        chassis_number

        vehicle_model {
          id

          name

          vehicle_make {
            id

            name
          }
        }

        year_of_manufacturing

        body_type {
          id

          name
        }

        seats

        engine_capacity

        year_of_registration

        purchage_date

        altered_vehicle_design

        other_usage

        repair_state
      }

      quotes {
        id

        premium

        proposed_inception_date

        proposed_expiry_date

        state

        created_at

        updated_at

        insurance_company {
          id

          name
        }

        quotation_date

        policy_purchase_date

        customer_review_remarks

        intermediary_review_date

        customer_acknowledgement_date

        broker_review_remarks

        details

        currency {
          id

          name

          symbol
        }

        review_intermediary_user {
          id

          name
        }

        intermediary_user {
          id

          name

          intermediary {
            id

            name

            logo
          }
        }
      }

      insurance_product {
        id

        name
      }

      policy_number

      state

      proposed_inception_date

      proposed_expiry_date

      accepted_quote_id

      payment_status

      payment_channel

      payment_ref

      premium

      payment_date

      customer {
        id

        full_name
      }

      currency {
        id

        name

        symbol
      }

      customer_acceptance_date

      no_claim_years

      buy_back_excess
    }
  }
`

export const NEW_REQUEST_NOT_QUOTED = gql`
  query RequestsNotQuotedByIntermediary(
    $intermediary_id: uuid
    $state: [String!]
  ) {
    RequestsNotQuotedByIntermediary: policy_request(
      order_by: { created_at: desc }
      where: {
        _or: [
          { quote_count: { _eq: 0 } }
          {
            quotes: {
              intermediary_user: { intermediary_id: { _neq: $intermediary_id } }
            }
          }
        ]
        state: { _in: $state }
      }
    ) {
      id

      request_code

      grabbed_intermediary_id

      created_at

      insurance_product {
        id

        name

        product_type {
          id

          name
        }
      }

      property_policy_details {
        id

        name

        physical_address

        gh_gps

        have_insured_before

        is_solely_residential

        is_for_commerce

        construction_year

        any_financial_interest

        rebuilding_cost

        insure_walls

        insure_outhouse

        outhouse_rebuilding_cost

        walls_rebuilding_cost

        content_sum_insured

        public_liability_sum_insured

        public_accident_sum_insured

        rent_sum_insured

        beneficiary_name

        beneficiary_email

        beneficiary_phone_number

        beneficiary_id

        purchase_for_self

        policy_contents {
          id

          name

          value
        }

        policy_materials {
          id

          building_material {
            id

            name
          }
        }

        policy_options {
          id

          insurance_product {
            id

            name
          }
        }

        policy_persons {
          id

          name

          sum_assured

          date_of_birth

          occupation

          sum_assured
        }

        policy_roofings {
          id

          roofing_material {
            id

            name
          }
        }
      }

      motor_request_detail {
        mileage

        color

        usage_type

        cost_price

        increase_thirparty_damage_limit

        registration_number

        chassis_number

        vehicle_model {
          id

          name

          vehicle_make {
            id

            name
          }
        }

        year_of_manufacturing

        body_type {
          id

          name
        }

        seats

        engine_capacity

        year_of_registration

        purchage_date

        altered_vehicle_design

        other_usage

        repair_state
      }

      quotes {
        id

        premium

        proposed_inception_date

        proposed_expiry_date

        state

        created_at

        updated_at

        insurance_company {
          id

          name
        }

        quotation_date

        policy_purchase_date

        customer_review_remarks

        intermediary_review_date

        customer_acknowledgement_date

        broker_review_remarks

        details

        currency {
          id

          name

          symbol
        }

        review_intermediary_user {
          id

          name
        }

        intermediary_user {
          id

          name

          intermediary {
            id

            name

            logo
          }
        }
      }

      insurance_product {
        id

        name
      }

      policy_number

      state

      proposed_inception_date

      proposed_expiry_date

      accepted_quote_id

      payment_status

      payment_channel

      payment_ref

      premium

      payment_date

      customer {
        id

        full_name

        phone_number
      }

      currency {
        id

        name

        symbol
      }

      customer_acceptance_date

      no_claim_years

      buy_back_excess
    }
  }
`

export const GRABBED_REQUEST = gql`
  query GrabbedRequest {
    RequestsNotQuotedByIntermediary: policy_request(
      order_by: { created_at: desc }
      where: { grabbed_intermediary_id: { _is_null: false } }
    ) {
      id

      request_code

      created_at
      quote_count
      insurance_product {
        id

        name

        product_type {
          id

          name
        }
      }

      property_policy_details {
        id

        name

        physical_address

        gh_gps

        have_insured_before

        is_solely_residential

        is_for_commerce

        construction_year

        any_financial_interest

        rebuilding_cost

        insure_walls

        insure_outhouse

        outhouse_rebuilding_cost

        walls_rebuilding_cost

        content_sum_insured

        public_liability_sum_insured

        public_accident_sum_insured

        rent_sum_insured

        beneficiary_name

        beneficiary_email

        beneficiary_phone_number

        beneficiary_id

        purchase_for_self

        policy_contents {
          id

          name

          value
        }

        policy_materials {
          id

          building_material {
            id

            name
          }
        }

        policy_options {
          id

          insurance_product {
            id

            name
          }
        }

        policy_persons {
          id

          name

          sum_assured

          date_of_birth

          occupation

          sum_assured
        }

        policy_roofings {
          id

          roofing_material {
            id

            name
          }
        }
      }

      motor_request_detail {
        mileage

        color

        usage_type

        cost_price

        increase_thirparty_damage_limit

        registration_number

        chassis_number

        vehicle_model {
          id

          name

          vehicle_make {
            id

            name
          }
        }

        year_of_manufacturing

        body_type {
          id

          name
        }

        seats

        engine_capacity

        year_of_registration

        purchage_date

        altered_vehicle_design

        other_usage

        repair_state
      }

      quotes {
        id

        premium

        proposed_inception_date

        proposed_expiry_date

        state

        created_at

        updated_at

        insurance_company {
          id

          name
        }

        quotation_date

        policy_purchase_date

        customer_review_remarks

        intermediary_review_date

        customer_acknowledgement_date

        broker_review_remarks

        details

        currency {
          id

          name

          symbol
        }

        review_intermediary_user {
          id

          name
        }

        intermediary_user {
          id

          name

          intermediary {
            id

            name

            logo
          }
        }
      }

      insurance_product {
        id

        name
      }

      policy_number

      state

      proposed_inception_date

      proposed_expiry_date

      accepted_quote_id

      payment_status

      payment_channel

      payment_ref

      premium

      payment_date

      customer {
        id

        full_name
      }

      currency {
        id

        name

        symbol
      }

      customer_acceptance_date

      no_claim_years

      buy_back_excess
    }
  }
`

export const GET_PENDING_REQUESTS = gql`
  query getPendingRequests {
    requests(where: { status: { _eq: "pending" } }) {
      id
      request_type {
        name
      }
      customer {
        first_name
        last_name
        other_names
      }
      status
      created_at
    }
  }
`

export const GET_PROXY_PROJECTED_SALES = gql`
  query getIntermediaryProjectedSales {
    proxies(order_by: { name: asc }) {
      id
      name
      phone_numbers
      type
      active
      quotes_aggregate(where: { status: { _eq: "accepted" } }) {
        aggregate {
          sum {
            amount
          }
        }
      }
    }
  }
`

export const LOAD_COMPANIES = gql`
  query MyQuery {
    insurance_company {
      email
      digital_address
      approval_status
      id
      is_active
      logo
      name
      phone_number
      physical_address
      postal_address
    }
  }
`

export const ALL_VERIFIED_INTERMEDIARIES = gql`
  query getAllActiveProxies {
    proxies(order_by: { name: asc }, where: { active: { _eq: true } }) {
      id
      name
    }
  }
`

export const GET_ALL_ADMINS = gql`
  query getAllAdmins($offset: Int, $limit: Int) {
    admins(offset: $offset, limit: $limit) {
      id
      name
      role
      email
      department
      phone
      status
    }
    admins_aggregate {
      aggregate {
        count
      }
    }
  }
`

export const GET_INTERMEDIARY_FILES = gql`
  query GetIntermediaryFiles($id: String!) {
    files(where: { created_by: { _contains: { id: $id } } }) {
      id
      key
      type
      reference_id
    }
  }
`

// export const GET_INTERMEDIARY_FILES = gql`
//   query GetIntermediaryFiles($reference: String!) {
//     files(where: {reference_id: {_eq: $reference}}) {
//       id
//       key
//       type
//       reference_id
//     }
//   }
// `;

export const GET_COMISSIONS = gql`
  query getGeneralCommssions {
    general_commissions {
      amount
      broker_commission
      broker_commission_amt
      name
      proxy_id
      proxy_type
      quoted_amt
      sys_commission
      sys_commission_amt
    }
  }
`
