import React, { useState } from "react";
import "./auth.css";
import { useLocation, useHistory, NavLink } from "react-router-dom";
import { CREATE_ADMIN } from "../Graphql/Mutations";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  SuccessNotification,
  ErrorNotification,
} from "../components/Notification";
import { Spinner } from "react-bootstrap";
import bcrypt from "bcryptjs";

function Register(props) {
  const [email, setEmail] = useState("");
  const [fullname, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const history=useHistory()

  const [RegisterUser, { error, loading: RequestLoading }] = useMutation(
    CREATE_ADMIN,

    {
      onCompleted: (data) => {SuccessNotification("You have successfully created an account");history.push('/login')},
      onError: (error) => ErrorNotification(error),
    }
  );


  const hashpassword=(password)=>{
    var salt = bcrypt.genSaltSync(10);
    var hash = bcrypt.hashSync(password, salt);

    return hash

  }

  const submitrequest = (evt) => {
    evt.preventDefault();
    if (password !== confirmpassword) {
      ErrorNotification("Passwords do not match");
    } else {
      try {
        RegisterUser({
          variables: {
            name: fullname,

            email: email,

            phone: phone,

            password: hashpassword(password),
          },
        });
      } catch (error) {}
    }
  };

  

  return (
    <div>
      <main className="d-flex w-100">
        <div className="container d-flex flex-column">
          <div className="row vh-100">
            <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center mt-4">
                  <h1 className="h2">Create an account</h1>
                </div>

                <div className="card">
                  <div className="card-body">
                    <div className="m-sm-4">
                      {/* <div className="text-center">
										<img src="img/avatars/avatar.jpg" alt="Charles Hall" className="img-fluid rounded-circle" width="132" height="132" />
									</div> */}
                      <form>
                        <div className="mb-3">
                          <label className="form-label">Full Name</label>
                          <input
                            onChange={(e) => setFullName(e.target.value)}
                            className="form-control form-control-lg"
                            type="text"
                            name="fullname"
                          />
                        </div>

                        <div className="mb-3">
                          <label className="form-label">Email</label>
                          <input
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control form-control-lg"
                            type="email"
                            name="email"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Phone Number</label>
                          <input
                            onChange={(e) => setPhone(e.target.value)}
                            className="form-control form-control-lg"
                            type="number"
                            name="number"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Password</label>
                          <input
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control form-control-lg"
                            type="password"
                            name="password"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            {" "}
                            Confirm Password
                          </label>
                          <input
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="form-control form-control-lg"
                            type="password"
                            name="password"
                          />
                        </div>
                        <div className="text-center mt-3">
                          <button
                            onClick={(e) => submitrequest(e)}
                            className="btn btn-lg btn-primary"
                          >
                            {RequestLoading ? (
                              <Spinner animation="grow" />
                            ) : (
                              "Create an account"
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                    <div style={{ textAlign: "center", marginTop: 20 }}>
                      <NavLink to="/login">
                        Already have an account? Login
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Register;
