import { gql } from '@apollo/client'
// export const CREATE_ADMIN = gql`
//   mutation createAdmin(
//     $name: String
//     $email: String
//     $phone: bigint
//     $password: String
//     $role: String
//     $department_id: uuid!
//   ) {
//     insert_admin_user_one(
//       object: {
//         name: $name
//         email: $email
//         phone_number: $phone
//         password: $password
//         role: $role
//         department_id: $department_id
//       }
//       on_conflict: {
//         constraint: admin_user_email_key
//         update_columns: updated_at
//       }
//     ) {
//       id

//       name

//       email

//       phone_number

//       role

//       password

//       is_active
//     }
//   }
// `

export const CREATE_ADMIN = gql`
  mutation InsertAdmin($admin:admins_insert_input!) {
    insert_admins_one(object: $admin) {
      id
      name
    }
  }
`;

export const ACTIVATE_PROXY = gql`
  mutation activateProxy($_proxyID: uuid) {
    update_proxies(where: { id: { _eq: $_proxyID } }, _set: { active: true }) {
      affected_rows
    }
  }
`

export const DEACTIVATE_PROXY = gql`
  mutation activateProxy($_proxyID: uuid) {
    update_proxies(where: { id: { _eq: $_proxyID } }, _set: { active: false }) {
      affected_rows
    }
  }
`

export const SET_INTERMEDIARY_RATE = gql`
  mutation setIntermediaryCommissionRate(
    $intermediary_id: uuid!
    $commission_rate: float8
  ) {
    update_intermediary_by_pk(
      pk_columns: { id: $intermediary_id }
      _set: { system_commission_rate: $commission_rate }
    ) {
      id

      system_commission_rate
    }
  }
`

export const SEND_NOTIFICATION_CUSTOMER = gql`
  mutation SendNotificationCustomer(
    $id: String!
    $name: JSON
    $username: String!
    $id1: ID!
    $messageShort: String!
    $subject: String!
  ) {
    data: send_customer_notification(
      request: {
        id: $id1
        subject: $subject
        messageShort: $messageShort
        createdBy: { id: $id, username: $username, name: $name }
      }
    ) {
      message
      data
    }
  }
`

export const CREATE_INSURANCE_PRODUCT = gql`
  mutation CreateInsuranceProduct(
    $product_type_id: uuid!
    $name: String
    $description: String
    $requirements: String
    $is_active: Boolean
  ) {
    insert_insurance_product_one(
      object: {
        product_type_id: $product_type_id
        name: $name
        description: $description
        requirements: $requirements
        is_active: $is_active
      }
    ) {
      id

      name

      product_type {
        id

        name
      }

      description

      requirements

      is_active
    }
  }
`

export const UPDATE_INSURANCE_PRODUCT = gql`
  mutation updateInsuranceProduct(
    $insurance_product_id: uuid!
    $name: String
    $description: String
    $requirements: String
    $is_active: Boolean
  ) {
    update_insurance_product_by_pk(
      pk_columns: { id: $insurance_product_id }
      _set: {
        name: $name
        description: $description
        requirements: $requirements
        is_active: $is_active
      }
    ) {
      id

      name

      product_type {
        id

        name
      }

      description

      requirements

      is_active
    }
  }
`

// export const UPDATE_ADMIN = gql`
//   mutation updateAdmin(
//     $id: uuid!
//     $name: String
//     $email: String
//     $phone: bigint
//     $department_id: uuid
//     $role: String
//   ) {
//     admin: update_admin_user_by_pk(
//       pk_columns: { id: $id }
//       _set: {
//         department_id: $department_id
//         name: $name
//         email: $email
//         phone_number: $phone
//         role: $role
//       }
//     ) {
//       id

//       role

//       department {
//         id

//         name
//       }

//       name

//       email

//       phone_number

//       is_active
//     }
//   }
// `

// Deprecated code
// export const UPDATE_ADMIN = gql`
//   mutation update_an_admin(
//     $id:uuid!,
//     $name:String,
//     $role:String,
//     $email:String,
//     $department:String,
//     $phone:String,
//     $status:Boolean,
//     $updated_at:timestamp
//   ) {
//     update_admins_by_pk(
//       pk_columns: {id:$id}
//       _set: {
//         name:$name
//         role:$role
//         email:$email
//         department:$department
//         phone:$phone
//         status:$status
//       }
//     ) {
//       id
//       name
//     }
//   }
// `;

export const UPDATE_ADMIN = gql`
  mutation UpdateAdmin($id:uuid!, $admin:admins_set_input) {
    update_admins_by_pk(
      pk_columns: {id: $id}
      _set:$admin
    ){
      id
    }
  }
`;

export const UPDATE_ADMIN_STATUS = gql`
  mutation blockAdmin($id: uuid!, $is_active: Boolean) {
    admin: update_admin_user_by_pk(
      pk_columns: { id: $id }
      _set: { is_active: $is_active }
    ) {
      id

      role

      department {
        id

        name
      }

      name

      email

      phone_number

      password

      is_active
    }
  }
`

export const SMS_LOG = gql`
  mutation createSMSLog(
    $recipient_id: uuid
    $schedule_date: timestamptz
    $sender_id: uuid
    $sent_date: timestamptz
    $status: String
    $title: String
    $message: String
  ) {
    insert_sms_log_one(
      object: {
        recipient_id: $recipient_id
        schedule_date: $schedule_date
        sender_id: $sender_id
        sent_date: $sent_date
        status: $status
        title: $title
        message: $message
      }
    ) {
      id
    }
  }
`

export const SET_BULK_SMS_LOG = gql`
  mutation createBulkSMSLog($messages: [sms_log_insert_input!]!) {
    insert_sms_log(objects: $messages) {
      returning {
        id
      }
    }
  }
`

export const SUBMIT_CUSTOMER_REQUEST = gql`
  mutation CreateMotorQuote(
    $inception_date: date
    $expiry_date: date
    $no_claim_years: Int
    $buy_back_excess: Boolean
    $currency_id: uuid
    $insurance_product_id: uuid
    $usage_type: String
    $increase_thirparty_damage_limit: Boolean
    $registration_number: String
    $mileage: float8
    $color: String
    $model_id: uuid
    $year_of_manufacturing: Int
    $body_type_id: uuid
    $seats: Int
    $engine_capacity: float8
    $year_of_registration: Int
    $purchage_date: date
    $altered_vehicle_design: Boolean
    $other_usage: String
    $repair_state: Boolean
    $chassis_number: String
    $beneficiary_name: String
    $beneficiary_email: String
    $beneficiary_phone_number: bigint
    $beneficiary_id: String
    $third_party_damage: Boolean
    $value_of_vehicle: float8
    $purchase_for_self: Boolean
    $customer_id: uuid
  ) {
    insert_motor_request_detail_one(
      object: {
        beneficiary_name: $beneficiary_name
        beneficiary_email: $beneficiary_email
        beneficiary_phone_number: $beneficiary_phone_number
        beneficiary_id: $beneficiary_id
        third_party_damage: $third_party_damage
        purchase_for_self: $purchase_for_self
        mileage: $mileage
        color: $color
        usage_type: $usage_type
        cost_price: $value_of_vehicle
        increase_thirparty_damage_limit: $increase_thirparty_damage_limit
        registration_number: $registration_number
        chassis_number: $chassis_number
        model_id: $model_id
        year_of_manufacturing: $year_of_manufacturing
        body_type_id: $body_type_id
        seats: $seats
        engine_capacity: $engine_capacity
        year_of_registration: $year_of_registration
        purchage_date: $purchage_date
        altered_vehicle_design: $altered_vehicle_design
        other_usage: $other_usage
        repair_state: $repair_state
        policy_request: {
          data: {
            customer_id: $customer_id
            proposed_inception_date: $inception_date
            proposed_expiry_date: $expiry_date
            no_claim_years: $no_claim_years
            buy_back_excess: $buy_back_excess
            currency_id: $currency_id
            insurance_product_id: $insurance_product_id
          }
        }
      }
    ) {
      id

      policy_request_id
    }
  }
`

export const UPDATE_POLICY_REQUEST = gql`
  mutation updateRequest($requestID: uuid, $motor_details: jsonb) {
    update_requests(
      where: { id: { _eq: $requestID } }
      _set: { motor_details: $motor_details, created_by: "self_upload_updated" }
    ) {
      affected_rows
    }
  }
`

export const ADD_GRABBING_REQUEST = gql`
  mutation grabbed_policy_requests(
    $policy_request_ids: [uuid!]
    $intermediary_id: uuid
    $admin_id: uuid
  ) {
    update_policy_request(
      where: { id: { _in: $policy_request_ids } }

      _set: {
        grabbed_intermediary_id: $intermediary_id
        grabbed_at: now
        grabbed_admin_id: $admin_id
      }
    ) {
      affected_rows
    }
  }
`
export const ASSIGN_REQUESTS_TO_PROXIES = gql`
  mutation assignRequestsToProxy($objects: [assigned_requests_insert_input!]!) {
    insert_assigned_requests(objects: $objects) {
      returning{
        id
        request_id
        proxy_id
        admin_id
      }
    }
  }
`

export const ADD_ADMIN = gql`
  mutation insertSingleAdmin($object:admins_insert_input!) {
    insert_admins_one(object: $object){
      id
    }
  }
`;

export const DELETE_ADMIN = gql`
  mutation DeleteAdmin($id:uuid!) {
    delete_admins_by_pk(id:$id){
      id
    }
  }
`;

export const RESET_PASSWORD =gql`
mutation update_admin($admin_id: uuid!, $password: String) {
  update_admins_by_pk(pk_columns: {id: $admin_id}, _set: {password: $password, reset_password_request: true}) {
    name
    phone
  }
}
`


export const CHANGE_PASSWORD =gql`
mutation update_admin($admin_id: uuid!, $password: String) {
  update_admins_by_pk(pk_columns: {id: $admin_id}, _set: {password: $password, reset_password_request: false}) {
    name
    phone
    id
  }
}
`


