import React from 'react';
import './App.css';
import Spinner from './components/Spinner';
import { Content } from './content';
import { BrowserRouter} from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { client } from './Appoloclient';
import toast, { Toaster } from 'react-hot-toast';

const App = () => {
  return (

    <ApolloProvider client={client}>
      <BrowserRouter>
      <Toaster />
          <React.Suspense fallback={<Spinner />}>
            <Content />
          </React.Suspense>
        </BrowserRouter>
    </ApolloProvider> 
  );
};

export default App;

// Files: Any form of files including images, pdfs
// Insurance Companies
// Quotes: Are from brokers (kind of an estimate) (customer = request, broker = quote)
// Once a customer pays for a quote, it becomes a policy
// Broker: A broker helps u to get a fair deal
// Proxies (Agents):
