import React from 'react'
import App from './App'
import { Provider as AuthProvider } from './Context'
import { client } from './Appoloclient';

const AppWrapper = () => {
  return (
    <AuthProvider client={client}>
      <App />
    </AuthProvider>
  )
}

export default AppWrapper