import { gql } from "@apollo/client";

export const GET_REQUEST_TYPES = gql`
query GetAllRequestTypes {
  request_types(
    where: { active: { _eq: true } }
    order_by: { name: asc }
  ) {
    id
    name
    code
    description
    image
    properties    
  }
}
`;

// id
// name
// code
// description
// image
// properties